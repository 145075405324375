.square {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.5vw;
  height: 4.5vw;
  color: #000;
  font-size: 4.8rem;
  line-height: 1.25;
  background-color: #FFF;
  border: 4px solid #000;
  cursor: pointer;
  z-index: 100;

  img {
    width: 100%;
  }
}