.menu__item {
  flex-basis: calc(25% - 4px);
  margin: 2px;
  overflow: hidden;
  cursor: pointer;
  img {
    display: block;
    width: 100%;
    height: auto;
    transform: scale(95%);
  }
  &:focus {
    border-radius: 5px;
  }
  &--active {
    border: 2px solid #FF5722;
    outline-offset: -1px;
    border-radius: 5px;

    img {
      animation: pulse 1.5s linear infinite;
    }
  }
}