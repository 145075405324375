.board {
  position: relative;
  border-spacing: 0;
  border: 4px solid #000;
  z-index: 10;
  &__container {
    position: relative;
    margin-bottom: 50px;
    padding-bottom: 50px;
    padding-right: 50px;
    &:before {
      position: absolute;
      top: 40px;
      left: 40px;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      content: "";
      background: #0073B2;
      border-radius: 16px;
      z-index: 0;
    }
  }
  &__align {
    position: relative;
    display: flex;
    z-index: 15;
  }
  &__XRow {
    position: relative;
    display: flex;
    width: max-content;
    margin-left: auto;
    border: 4px solid #000;
    z-index: 15;

    &--margin-bottom {
      margin-bottom: 14px;
    }

    &--margin-top {
      margin-top: 14px;
      margin-bottom: -4px;
    }
  }
  &__YRow {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 14px;
    border: 4px solid #000;
    z-index: 1;
  }
  td {
    padding: 0;
  }
}

.contrast .board__container:before {
  top: -20px;
  left: -20px;
  width: 100%;
  height: 100%;
  background-color: #FFF;
}