.intro {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  &__half {
    flex-basis: 50%;
    @media screen and (max-width: 1200px){
      flex-basis: 100%;
    }
  }
  p {
    margin-top: 40px;
    font-size: 2.4rem;
  }
  img {
    display: block;
    max-width: 100%;
    max-height: 470px;
    margin: 0 auto;
  }
  .button {
    margin: 40px auto 20px;
  }
}