.footer {
  place-self: flex-end;
  flex-basis: 100%;
  width: 100%;;
  padding: 40px 0 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  &__ue {
    display: block;
    max-width: 100%;
    margin: 0 auto 20px;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__links {
    display: flex;
    gap: 38px;
    margin-top: 10px;
  }
  span {
    margin-top: 10px;
    font-size: 1.6rem;
  }
  a {
    font-size: 1.6rem;
  }
}