.scenarios-view {
  justify-content: flex-start;
  padding: 48px 64px;
  background-color: #FFF;
  border: 4px solid #0073B2;
  border-radius: 40px;

  h1 {
    margin-bottom: 25px;
    color: #0073B2;
    font-size: 3rem;
  }

  h2 {
    min-width: 100%;
  }

  .content {
    min-width: 100%;
  }

  img, video {
    width: 650px;
    max-width: 100%;
    margin: 30px auto 10px;
  }

  .row {
    margin-top: 40px;
  }
}

.contrast .scenarios-view {
  background-color: #000;
  border-color: #FFF;
  h1 {
    color: #FFF;
  }
}