.ct {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  width: 148px;
  height: 64px;
  &__slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFF;
    border: 4px solid #0073B2;
    border-radius: 34px;
    -webkit-transition: .4s;
    transition: .4s;
    cursor: pointer;
    &:before {
      position: absolute;
      left: 4px;
      bottom: 4px;
      content: "";
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background-color: #0073B2;
      -webkit-transition: .4s;
      transition: .4s;
    }
    &--true {
      &:before {
        -webkit-transform: translateX(84px);
        -ms-transform: translateX(84px);
        transform: translateX(84px);
      }
    }
  }
  &__input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  &__value {
    position: absolute;
    color: #0073B2;
    font-size: 2.8rem;
    text-transform: uppercase;
    z-index: 1;
    &--on {
      left: 23px;
      top: 16px;
    }
    &--off {
      right: 23px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.contrast .ct {
  &__slider {
    background-color: #000;
    border-color: #FFF;
    &:before {
      background-color: #FFF;
    }
  }
  &__value {
    color: #FFF;
  }
}