.fc {
  display: flex;
  align-items: center;
  &__button {
    position: relative;
    display: inline-block;
    width: 64px;
    height: 64px;
    padding: 0;
    color: #ffffff;
    font-size: 68px;
    line-height: 60px;
    background-color: #0073B2;
    border-radius: 50%;
    border: 4px solid #0073B2;
    cursor: pointer;
    &:hover {
      background-color: #0073B2;
    }
    &--plus:after, &--minus:after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      width: 37px;
      transform: translate(-50%, -50%);
    }
    &--plus:after {
      height: 37px;
      background-image: url('./../../images/plus.svg');
      background-repeat: no-repeat;
    }
    &--minus:after {
      height: 4px;
      background-color: #FFF;
    }
  }
  span {
    margin: 0 20px;
    font-size: 68px;
    line-height: 1;
    font-weight: bold;
  }
}