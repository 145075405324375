.search {
  padding-left: 30px;
  &__input {
    width: 100%;
    margin-bottom: 8px;
    padding: 7px 20px;
    border: 4px solid #0073B2;
    border-radius: 20px;
  }
  &__elements {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
    padding-left: 0 !important;
    background-color: #FFF;
    border: 4px solid #0073B2;
    border-radius: 10px;
  }
  &__info {
    margin: 5px 24px 20px;
    font-size: 2rem;
  }
}

.contrast .search {
  &__input {
    color: #FFF;
    border-color: #FFF;
    background-color: #000;
  }
  &__elements {
    border-color: #FFF;
    background-color: #000;
  }
}