* {
  box-sizing: border-box;
}
*:focus {
  outline: 2px dashed #ff9f00 !important;
  outline-offset: -2px;
}

html {
  font-family: 'Atkinson Hyperlegible', sans-serif;
  font-size: 62.5%;
  line-height: 1.25;
  -webkit-text-size-adjust: 100%; /* 2 */
}

body {
  margin: 0;
}

#root {
  width: 100%;
  background: {
    image: url('./../images/bg-shapes.svg');
    size: contain;
    position: left center;
    repeat: no-repeat;
    attachment: fixed;
  }
}

.app-wrapper {
  color: #000;
  background: {
    size: 160px 160px;
    image:
      linear-gradient(to right, rgba(68, 138, 255, 0.5) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(68, 138, 255, 0.5) 1px, transparent 1px);
    position: -2px -2px;
  }
  &.contrast {
    color: #FFF;
    background: {
      color: #000;
      image: none;
    }
  }
}

.app {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100vh;
  padding: 0 4vw;
}

main {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  place-self: flex-start;
  width: 100%;
  min-height: 100%;
}

.view-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  width: 100%;

  &--center {
    align-content: center;
  }

  h1 {
    width: 100%;
    margin-bottom: 75px;
    text-align: center;
  }
}

.menu + .view-content {
  max-width: calc(100% - 430px);
}

.contrast {
  color: #FFF;
  .app {
    background: {
      color: #000;
      image: none;
    }
    a {
      color: #FFF;
    }
    button, .button {
      background-color: #000;
      border-color: #FFF;
      color: #FFF;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px 45px;
  width: 100%;

  &.row--3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.flex-break {
  flex-basis: 100%;
}

.warning {
  padding: 20px;
  color: #FFF;
  background-color: #f17c43;
  border-radius: 20px;
  text-shadow: 0px 1px 3px #000000;
  font-weight: bold;
  letter-spacing: 1px;
}
.contrast .warning {
  color: #000;
  background-color: #ffffff;
  text-shadow: none;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
  max-width: 90%;
  margin: 0;
  padding: 27px 0;
  color: #FFF;
  font-size: 3.6rem;
  font-weight: bold;
  background-color: #0073B2;
  border-radius: 50px;
  border: 4px solid #0073B2;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  word-break: break-word;

  &--white {
    color: #0073B2;
    background-color: #FFF;
  }

  &--high {
    height: 192px;
  }

  &--capitalize {
    text-transform: capitalize;
  }
}

h1 {
  margin: 0 0 15px;
  font-size: 4rem;
}

h2 {
  margin: 0 0 5px;
  font-size: 2.4rem;
}

h3 {
  margin: 0 0 5px;
  font-size: .2rem;
}

p {
  margin: 0 0 15px;
  font-size: 2rem;
}

ul {
  list-style-type: circle;
  font-size: 2rem;
  padding-left: 20px;
  margin: 15px 0;
}

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

a {
  background-color: transparent;
  text-decoration: none;
  color: #000;
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button,
input { /* 1 */
  overflow: visible;
}

button,
select { /* 1 */
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

@keyframes pulse {
  0% {
      transform: scale(95%);
  }
  50% {
      transform: scale(105%);  
  }
  100% {
      transform: scale(95%);
  }
}