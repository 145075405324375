.menu {
  list-style-type: none;
  min-width: 380px;
  width: 380px;
  max-width: 100%;
  margin: 0 30px 0 0;
  padding-left: 0;
  color: #0073B2;
  font-size: 2.8rem;
  ul {
    list-style-type: none;
    padding-left: 30px;
  }
  &__list {
    list-style-type: none;
    padding-left: 30px;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }
  &__elements {
    flex-wrap: wrap;
    padding-left: 0 !important;
    background-color: #FFF;
    border: 4px solid #0073B2;
    border-radius: 10px;
  }
  &__link {
    position: relative;
    display: block;
    width: fit-content;
    margin-bottom: 8px;
    padding: 6px 24px;
    color: #0073B2;
    background-color: #FFF;
    border: 4px solid #0073B2;
    border-radius: 20px;
    cursor: pointer;
    svg {
      display: inline-block;
      margin-top: -5px;
      margin-left: 17px;
      transition: all .5s;
      path {
        stroke: #0073B2;
      }
    }
    &--light {
      color: #00B0FF;
      border-color: #00B0FF;
      svg path {
        stroke: #00B0FF;
      }
    }
    &--orange {
      color: #FF5722;
      border-color: #FF5722;
      svg path {
        stroke: #FF5722;
      }
    }
    &--list-visible {
      svg {
        transform: rotate(180deg);
        transition: all .5s;
      }

      & + ul {
        display: block;
        max-height: 10000px;
        opacity: 1;
        transition: max-height 2s;
        margin: 4px 0 4px;
        &.menu__elements {
          display: flex;
        }
      }
    }
    &--list-hidden + ul {
      display: none;
    }
    &--active {
      color: #FFF;
      background-color: #FF5722;
      border-color: #FF5722;
      svg path {
        stroke: #FFF;
      }
    }
  }
}

.contrast .menu {
  color: #FFF;
  &__elements {
    border-color: #FFF;
    background-color: #000;
  }
  &__link {
    border-color: #FFF;
    background-color: #000;
    svg path {
      stroke: #FFF;
    }
    &--active {
      color: #000 !important;
      background-color: #FFF;
    }
  }
}