.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  place-self: flex-start;
  flex-basis: 100%;
  padding: 45px 0 20px;
  gap: 20px;

  &__logo {
    display: block;
    width: 124px;
    height: auto;
    object-fit: contain;
  }
  &__right-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8vw;
    @media screen and (max-width: 610px){
      align-items: start;
      gap: 20px;
    }
  }
}